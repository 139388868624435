<template>
  <div>
    <vb-edit-category
      :modal="modalVisible"
      :category-id="categoryId"
      :locales="locales"
      @reload="reload()"
      @loaded="editLoading = 0"
      @hide-modal="hideModal()"
    />
    <div class="clearfix">
      <a-dropdown
        class="my-2"
        v-if="selectedRowKeys.length"
      >
        <template #overlay>
          <a-menu>
            <a-menu-item key="1" @click="deleteCategoriesModal = true">
              Удалить выбранные категории
            </a-menu-item>
          </a-menu>
        </template>
        <a-button type="primary">
          Выберите действие
          <DownOutlined/>
        </a-button>
      </a-dropdown>
      <a-typography-text v-if="selectedRowKeys.length" secondary class="mx-3">
        (Выделено: {{ selectedRowKeys.length }} элементов)
      </a-typography-text>
      <a-button
        v-if="showSelectAllButton"
        @click="selectAll"
        type="link"
      >Выделить все ({{ total }})
      </a-button>
      <a-button
        v-if="selectedRowKeys.length"
        @click="clearSelection"
        type="text"
        class="text-red"
      >
        Отменить
      </a-button>
    </div>
    <a-table
      :row-selection="rowSelection"
      :columns="columns"
      :data-source="dataSource"
      :loading="loading || sync"
      :pagination="false"
      @change="tableChange"
      row-key="id"
      id="data-table-categories"
    >
      <template #title>
        <div class="pb-1 overflow-hidden">
          <a-select
            v-model:value="selectedBrand"
            :options="brands"
            @change="updateRoute"
            :option-filter-prop="'label'"
            style="width: 300px;margin: 4px"
            placeholder="Бренд"
            size="large"
            show-search
            allow-clear
          />
          <vb-create-category
            @reload="reload"
          />
          <a-dropdown>
            <template #overlay>
              <a-menu>
<!--                <a-menu-item key="1" @click="confirmFetch('online')">-->
<!--                  <SyncOutlined :spin="sync"/>-->
<!--                  Подтянуть с резидента (OnlineOrder/Menu)-->
<!--                </a-menu-item>-->
<!--                <a-menu-item key="2" @click="confirmFetch('product')">-->
<!--                  <SyncOutlined :spin="sync"/>-->
<!--                  Подтянуть с резидента (Product/List)-->
<!--                </a-menu-item>-->
                <a-menu-item key="4" @click="confirmFetch">
                  <SyncOutlined :spin="sync"/>
                  Подтянуть с резидента
                </a-menu-item>
                <a-menu-item key="3" :loading="importLoading">
                  <a-upload
                    v-model:file-list="fileList"
                    name="products"
                    :multiple="true"
                    :action="actionUrl"
                    :headers="headers"
                    @change="importExcel"
                  >
                    <DownloadOutlined/>
                    Загрузить файл
                  </a-upload>
                </a-menu-item>
              </a-menu>
            </template>
            <a-button type="dashed" class="float-right">
              Импорт
              <DownOutlined/>
            </a-button>
          </a-dropdown>
          <a-button class="float-right" type="link" href="https://s3.devspace.uz/tips/products.xlsx">
            <template #icon><file-excel-two-tone /></template>
            <span>Шаблон для импорта</span>
          </a-button>
          <a-button class="float-right" type="link" danger @click="deleteAllModal = true">
            <template #icon><delete-outlined /></template>
            <span>Удалить все категории</span>
          </a-button>
        </div>
      </template>
      <template #name="{ record }">
        <div v-if="editNameId === record.id" class="row">
          <div class="col-md-10">
            <a-input
              @pressEnter="updateColumn(record['uuid'], record.name); editNameId = 0"
              v-model:value="record.name"
              :id="'category-' + editNameId"
            />
          </div>
          <div class="col-md-2 text-center row">
            <a-button
              @click="updateColumn(record['uuid'], record.name); editNameId = 0"
              type="text"
              shape="round"
              class="p-0"
            >
              <template #icon>
                <SaveOutlined/>
              </template>
            </a-button>
          </div>
        </div>
        <div v-else>
          <div class="pull-left" :style="{ lineHeight: '30px' }">{{ record.name }}</div>
          <a-button @click="onEditNameId(record.id)" type="text" shape="round" class="px-2 pull-right">
            <template #icon>
              <EditOutlined/>
            </template>
          </a-button>
        </div>
      </template>
      <template #date="{ record }">
        <span v-if="record['id'] !== '-'">
          {{ formattedDatetime(record['added_on']) }}
        </span>
        <span v-if="record.author">
          ({{ record.author }})
        </span>
      </template>
      <template #status="{ record }" v-if="true">
        <div v-if="record['id'] !== '-'">
          <a-tag
            @click="updateColumn(record['uuid'], record.name, 'status', 0)"
            v-if="record.status"
            color="#108ee9"
            class="cursor-pointer"
          >Активный
          </a-tag>
          <a-tag
            @click="updateColumn(record['uuid'], record.name, 'status', 1)"
            class="cursor-pointer"
            v-else
          >Неактивный
          </a-tag>
        </div>
      </template>
      <template #action="{ record }">
        <div class="w-100 text-right">
          <a-tooltip placement="top" class="mx-2">
            <template #title>Удалить</template>
            <a @click="deleteModal = record['uuid']" class="float-right"><delete-outlined /></a>
          </a-tooltip>
          <a-tooltip placement="top" class="mx-2">
            <template #title>Редактировать</template>
            <a @click="updateModal(record['uuid'])" class="float-right">
              <a-spin v-if="editLoading === record['uuid']" size="small" />
              <form-outlined v-else />
            </a>
          </a-tooltip>
          <a-tooltip placement="top" class="mx-2">
            <template #title>Продукты</template>
            <router-link class="float-right" :to="{ name: 'products', params: { category: record['uuid'] } }">
              <i class="lnr lnr-dinner"></i>
            </router-link>
          </a-tooltip>
        </div>
      </template>
    </a-table>
    <div class="mt-4 text-right">
      <a-pagination v-if="total" v-model:current="page" :total="total"/>
    </div>
    <a-modal
      :width="700"
      class="store-edit-modal"
      title="Выберите приоритет"
      :visible="fetchModalVisible"
      :closable="true"
      @ok="fetchFromResident"
      @cancel="closeFetchModal"
      ok-text="Подтянуть"
      cancel-text="Закрыть"
    >
      <a-form layout="vertical">
        <div v-for="store in stores" :key="store.id">
          <a-form-item name="store" :label="store.name">
            <a-select
              :options="restaurants"
              :value="store['restaurant_id']"
              @change="updateRestaurantsList($event, store.id)"
              placeholder="Выберите ресторан"
              style="width: 100%;clear: both"
            />
          </a-form-item>
        </div>
      </a-form>
      <table class="table table-striped" style="border-top: none">
        <thead>
          <tr>
            <th style="border-top: none"></th>
            <th style="border-top: none">Приоритет POS</th>
            <th style="border-top: none">Приоритет DB</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Обновить категорию</td>
            <td>
              <a-radio-group v-model:value="priority.category">
                <a-radio :value="0"></a-radio>
              </a-radio-group>
            </td>
            <td>
              <a-radio-group v-model:value="priority.category">
                <a-radio :value="1"></a-radio>
              </a-radio-group>
            </td>
          </tr>
          <tr>
            <td>Название</td>
            <td>
              <a-radio-group v-model:value="priority.name">
                <a-radio :value="0"></a-radio>
              </a-radio-group>
            </td>
            <td>
              <a-radio-group v-model:value="priority.name">
                <a-radio :value="1"></a-radio>
              </a-radio-group>
            </td>
          </tr>
          <tr>
            <td>Фото</td>
            <td>
              <a-radio-group v-model:value="priority.photo">
                <a-radio :value="0"></a-radio>
              </a-radio-group>
            </td>
            <td>
              <a-radio-group v-model:value="priority.photo">
                <a-radio :value="1"></a-radio>
              </a-radio-group>
            </td>
          </tr>
          <tr>
            <td>Описание</td>
            <td>
              <a-radio-group v-model:value="priority.desc">
                <a-radio :value="0"></a-radio>
              </a-radio-group>
            </td>
            <td>
              <a-radio-group v-model:value="priority.desc">
                <a-radio :value="1"></a-radio>
              </a-radio-group>
            </td>
          </tr>
          <tr>
            <td>Цена</td>
            <td>
              <a-radio-group v-model:value="priority.price">
                <a-radio :value="0"></a-radio>
              </a-radio-group>
            </td>
            <td>
              <a-radio-group v-model:value="priority.price">
                <a-radio :value="1"></a-radio>
              </a-radio-group>
            </td>
          </tr>
          <tr>
            <td>ИКПУ</td>
            <td>
              <a-radio-group v-model:value="priority.spic">
                <a-radio :value="0"></a-radio>
              </a-radio-group>
            </td>
            <td>
              <a-radio-group v-model:value="priority.spic">
                <a-radio :value="1"></a-radio>
              </a-radio-group>
            </td>
          </tr>
          <tr>
            <td>Код упаковки</td>
            <td>
              <a-radio-group v-model:value="priority.code">
                <a-radio :value="0"></a-radio>
              </a-radio-group>
            </td>
            <td>
              <a-radio-group v-model:value="priority.code">
                <a-radio :value="1"></a-radio>
              </a-radio-group>
            </td>
          </tr>
          <!--<tr>
            <td>Owner Type</td>
            <td>
              <a-radio-group v-model:value="priority.owner">
                <a-radio :value="0"></a-radio>
              </a-radio-group>
            </td>
            <td>
              <a-radio-group v-model:value="priority.owner">
                <a-radio :value="1"></a-radio>
              </a-radio-group>
            </td>
          </tr>-->
        </tbody>
      </table>
    </a-modal>
    <a-modal
      v-model:visible="deleteModal"
      @cancel="deleteModal = null"
      :footer="null"
    >
      <a-typography-title :level="5">Вы действительно хотите удалить категорию вместе с продуктами?</a-typography-title>
      <div class="footer overflow-hidden pt-2">
        <a-button @click="deleteCategory" :loading="deleteLoading" type="primary" class="float-right">Да</a-button>
        <a-button @click="deleteModal = null" type="secondary" class="float-right mx-2">Нет</a-button>
      </div>
    </a-modal>
    <a-modal
      v-model:visible="deleteCategoriesModal"
      @cancel="deleteCategoriesModal = false"
      :footer="null"
    >
      <a-typography-title :level="5">Вы уверены что хотите удалить выбранные категории?</a-typography-title>
      <div class="footer overflow-hidden pt-2">
        <a-button @click="deleteCategories" :loading="multipleDeleteLoading" type="primary" class="float-right">Да</a-button>
        <a-button @click="deleteCategoriesModal = false" type="secondary" class="float-right mx-2">Нет</a-button>
      </div>
    </a-modal>
    <a-modal
      v-model:visible="deleteAllModal"
      @cancel="deleteAllModal = false"
      :footer="null"
    >
      <a-typography-title :level="5">Вы уверены что хотите удалить все категории?</a-typography-title>
      <div class="footer overflow-hidden pt-2">
        <a-button @click="deleteAll" :loading="deleteAllLoading" type="primary" class="float-right">Да</a-button>
        <a-button @click="deleteAllModal = false" type="secondary" class="float-right mx-2">Нет</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import {
  DownOutlined,
  DeleteOutlined,
  SyncOutlined,
  DownloadOutlined,
  EditOutlined,
  SaveOutlined,
  FileExcelTwoTone,
  FormOutlined
} from '@ant-design/icons-vue';
import {computed, nextTick, onMounted, reactive, ref, unref, watch} from 'vue';
import VbEditCategory from "@/views/store/menu/categories/edit";
import VbCreateCategory from "@/views/store/menu/categories/create";
import {onBeforeRouteUpdate, useRoute, useRouter} from "vue-router";
import {message} from 'ant-design-vue';
import apiClient from "@/services/axios";
import vuexStore from 'store'

let
  initialPriority = {
    name: 0,
    photo: 0,
    price: 0,
    desc: 0,
    spic: 0,
    code: 0,
    category: 0,
  },
  restaurants = ref([]),
  restaurant = ref([]),
  restaurantsList = ref([]),
  priority = reactive({ ...initialPriority }),
  showSelectAllButton = ref(false),
  editNameId = ref(0),
  editLoading = ref(0),
  importLoading = ref(false),
  categoryId = ref(0),
  locales = ref([]),
  dataSource = ref([]),
  loading = ref(false),
  modalVisible = ref(false),
  fetchModalVisible = ref(false),
  fetchModalType = ref('online'),
  total = ref(0),
  sync = ref(false);

const router = useRouter(),
  stores = ref([]),
  brands = ref([]),
  selectedBrand = ref('null'),
  selectedRowKeys = ref([]),
  token = vuexStore.get('accessToken'),
  route = useRoute(),
  deleteModal = ref(null),
  deleteAllModal = ref(false),
  deleteAllLoading = ref(false),
  deleteLoading = ref(false),
  multipleDeleteLoading = ref(false),
  deleteCategoriesModal = ref(false),
  initialParams = {
    page: 1,
    'per-page': 10,
    sort: '',
    name: '',
  },
  queryParams = reactive({...initialParams}),
  columns = [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      slots: {customRender: 'name'},
    },
    {
      title: 'UUID',
      dataIndex: 'uuid',
      key: 'uuid',
    },
    {
      title: 'Родитель',
      dataIndex: 'parent_id',
      key: 'parent_id',
    },
    {
      title: 'Кол-во продуктов',
      dataIndex: 'products_count',
      key: 'products_count',
    },
    {
      title: 'Добавлен',
      dataIndex: 'added_on',
      key: 'added_on',
      slots: {customRender: 'date'},
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      slots: {customRender: 'status'},
    },
    {
      key: 'action',
      slots: {customRender: 'action'},
    },
  ],
  fileList = ref([]),
  headers = {
    'x-access-token': `Bearer ${token}`,
  },
  actionUrl = `${process.env.VUE_APP_BACKEND_URL}/cabinet/menu/import-products?brand=${route.params.brand}`,
  importExcel = info => {
    if (info.file.status !== 'uploading') {
      // console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
      reload()
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  closeFetchModal = () => {
    fetchModalVisible.value = false;
    resetPriority()
  },
  resetPriority = () => {
    Object.assign(priority, initialPriority);
  },
  confirmFetch = (type = 'online') => {
    restaurants.value = []
    fetchModalType.value = type;
    fetchModalVisible.value = true;
    apiClient.get(`${process.env.VUE_APP_BACKEND_URL}/cabinet/menu/get-restaurant-id?brand=${route.params.brand}`)
      .then(res => {
        if (res.data.data) {
          restaurants.value = res.data.data['restaurants'];
          stores.value = res.data.data['stores'];
        }
      })
  },
  fetchFromResident = () => {
    sync.value = true
    closeFetchModal()
    // apiClient.post(`${process.env.VUE_APP_BACKEND_URL}/cabinet/menu/sync-products?brand=${route.params.brand}`, {
      // type: fetchModalType.value,
      // priority
    // })
    if (!restaurantsList.value || !restaurants.value.length) {
      return;
    }
    apiClient.post(`${process.env.VUE_APP_BACKEND_URL}/cabinet/menu/sync-products?brand=${route.params.brand}`, {
      restaurants: restaurantsList.value,
      priority
    }).then(() => {
    }).catch(() => {
    }).finally(() => {
      sync.value = false
      reload()
    })
  },
  formattedDatetime = (date) => {
    date = date.replace(/ /g, "T")
    let d = new Date(date)
    const timeFormatter = new Intl.DateTimeFormat('ru-RU', {
      timeStyle: 'medium',
    })
    const dateFormatter = new Intl.DateTimeFormat('ru-RU')
    return dateFormatter.format(d) + ' ' + timeFormatter.format(d)
  },
  updateParams = params => {
    Object.assign(queryParams, params)
  },
  tableChange = (pag, filters, sorter) => {
    if (typeof sorter.order == "string") {
      let sign = ''
      if (sorter.order === 'descend')
        sign = '-'
      updateParams({sort: sign + sorter.field})
    }
  },
  hideModal = () => {
    setTimeout(() => {
      modalVisible.value = false
    }, 100)
  },
  deleteAll = () => {
    deleteAllLoading.value = true
    return apiClient.delete(`menu/categories-delete-all?brand=${route.params.brand}`).then(response => {
      if (response) {
        message.success('Категории успешно удалены')
      }
    }).catch(() => {}).finally(() => {
      reload()
    }).finally(() => {
      deleteAllModal.value = false
      deleteAllLoading.value = false
    })
  },
  deleteCategories = () => {
    multipleDeleteLoading.value = true
    return apiClient.delete('menu/categories-delete', {
      data: {categories: selectedRowKeys.value},
    }).then(response => {
      if (response) {
        message.success('Категории успешно удалены')
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      reload()
    }).finally(() => {
      clearSelection()
      deleteCategoriesModal.value = false
      multipleDeleteLoading.value = false
    });
  },
  onSelectChange = changeableRowKeys => {
    selectedRowKeys.value = changeableRowKeys;
  },
  rowSelection = computed(() => {
    return {
      selectedRowKeys: unref(selectedRowKeys),
      onChange: onSelectChange,
      hideDefaultSelections: true,
      selections: null,
    };
  }),
  clearSelection = () => {
    selectedRowKeys.value = []
  },
  selectAll = () => {
    const params = {...queryParams}
    return apiClient.get(`/menu/category-ids?brand=${route.params.brand}`, {
      params,
    }).then(({data}) => {
      if (data['data']) {
        selectedRowKeys.value = data['data']
      }
    })
  },
  updateRoute = (brand) => {
    if (!brand) brand = 'null'
    router.push({
      name: 'categories',
      params: { brand: brand }
    })
  },
  updateColumn = (id, name, column, value) => {
    let data = {};
    data['name'] = name;
    data[column] = value;
    apiClient.put(`/menu/category-update?category=${id}`, data).then(response => {
      if (response) {
        message.success('Категория успешно обновлена')
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      reload()
    });
  },
  updateRestaurantsList = (value, id) => {
    const exists = restaurantsList.value.find(item => item['store_id'] === id)
    if (exists) {
      exists.value = value
    } else {
      restaurantsList.value.push({
        store_id: id,
        value: value
      })
    }
  },
  updateModal = (id) => {
    categoryId.value = id
    modalVisible.value = true
    editLoading.value = id
  },
  deleteCategory = () => {
    deleteLoading.value = true
    return apiClient.delete(`menu/category-delete?category=${deleteModal.value}`).then(response => {
      if (response) {
        message.success('Категория успешно удалена')
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      reload()
      deleteModal.value = null
      deleteLoading.value = false
    });
  },
  getData = async (brand, params) => {
    loading.value = true
    if (brand === 'null') {
      await apiClient.get('/menu/categories?brand=null', { params }).then(({data}) => {
        brand = data.data.brands.find(brand => brand.value !== 'null')?.value
        selectedBrand.value = brand
        router.replace({
          name: 'categories',
          params: { brand }
        })
      })
    }
    return await apiClient.get(`/menu/categories?brand=${brand}`, { params }).then(({data}) => {
      dataSource.value = data.data.items
      brands.value = data.data.brands
      locales.value = data.data.locales
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      loading.value = false
    });
  },
  reload = () => {
    getData(route.params.brand, {
      ...queryParams,
    })
  },
  onEditNameId = (id) => {
    editNameId.value = id;
    nextTick(() => {
      const input = document.getElementById(`category-${id}`);
      if (input)
        input.focus();
    })
  };

onBeforeRouteUpdate((to, from, next) => {
  getData(to.params.brand,{
    ...queryParams,
  })
  next()
})
onMounted(() => {
  selectedBrand.value = route.params.brand
  getData(route.params.brand, {
    ...queryParams,
  })
})

watch(queryParams, () => {
  router.push({
    name: 'categories',
    query: {
      ...route.query,
      ...queryParams,
    },
  })
  getData(route.params.brand, {
    ...route.query,
    ...queryParams,
  })
})

</script>
<style scoped>
@import "./index.css";
</style>
