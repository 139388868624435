<template>
  <a-button
    class="add-category ml-3"
    type="primary"
    @click="openModal"
  >
    Добавить категорию
  </a-button>
  <a-modal
    class="category-create-modal"
    title="Добавить категорию"
    :visible="modalVisible"
    :closable="true"
    :confirm-loading="!(!loading && !confirmLoading)"
    @ok="create"
    @cancel="resetForm"
  >
    <a-form :model="category">
      <div class="col-md-12">
        <a-upload
          name="image_url"
          list-type="picture-card"
          class="logo-uploader"
          :show-upload-list="false"
          :headers="getHeaders()"
          :action="getUrl"
          :before-upload="beforeUpload"
          @change="uploadImage"
        >
          <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
          <div v-else>
            <loading-outlined v-if="loading"></loading-outlined>
            <PlusOutlined v-else></PlusOutlined>
            <div class="ant-upload-text">Логотип</div>
          </div>
        </a-upload>
        <a-form-item label="Название категории">
          <a-input
            ref="categoryName"
            placeholder="Введите название категории"
            v-model:value="category.name"
          />
        </a-form-item>
        <div class="row">
          <div class="col-md-2">
            <a-form-item label="Статус">
              <a-switch
                checked-children="Вкл"
                un-checked-children="Выкл"
                v-model:checked="category.status" />
            </a-form-item>
          </div>
        </div>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue';
import {ref, reactive, toRaw, nextTick} from 'vue';
import { getHeaders } from "@/helpers/header"
import { getBase64 } from "@/helpers/base64"
import { message } from 'ant-design-vue';
import apiClient from "@/services/axios";
import Category from "@/models/Category";
import {useRoute} from "vue-router";

const emit = defineEmits(['reload']);

let imageUrl = ref(''),
  loading = ref(false),
  confirmLoading = ref(false),
  modalVisible = ref(false),
  categoryName = ref("categoryName"),
  initialCategory = new Category({}),
  category = reactive(initialCategory);

const
  route = useRoute(),
  uploadImage = info => {
    imageUrl.value = ''
    if (info.file.status === 'uploading') {
      loading.value = true;
      return;
    }

    if (info.file.status === 'done') {
      category.image_url = info.file.response.data
      getBase64(info.file.originFileObj, base64Url => {
        imageUrl.value = base64Url;
        loading.value = false;
      });
    }

    if (info.file.status === 'error') {
      loading.value = false;
      message.error('upload error');
    }
  },
  beforeUpload = file => {
    const isJpgOrPngOrSvg = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';

    if (!isJpgOrPngOrSvg) {
      message.error('You can only upload JPG, PNG or SVG file!');
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }

    return isJpgOrPngOrSvg && isLt2M;
  },
  openModal = () => {
    modalVisible.value = true;
    nextTick(() => categoryName.value?.focus());
  },
  getUrl = () => {
    return process.env.VUE_APP_BACKEND_URL + '/cabinet/menu/upload-image'
  },
  resetForm = () => {
    Object.assign(category, new Category({}));
    modalVisible.value = false;
  },
  create = () => {
    confirmLoading.value = true;

    let model = toRaw(category);
    model.status = model.status ? 1 : 0
    model.brand_id = route.params.brand

    return apiClient.post('/menu/category-create', model).then(function (response) {
      if (response) {
        resetForm()
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      modalVisible.value = false;
      confirmLoading.value = false;
      emit('reload')
    })
  }
</script>

<style scoped>
@import "./create.css";
</style>
